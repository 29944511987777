import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import project from '../../assets/images/projects/formazionesviluppo.jpg'
import project1 from '../../assets/images/projects/patac1.jpg'
import project2 from '../../assets/images/projects/patac2.jpg'
import project3 from '../../assets/images/projects/patac3.jpg'
import {Link} from 'gatsby'
import Lightbox from 'react-image-lightbox'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Formazione Sviluppo" 
                homePageText="Digital transformation" 
                homePageUrl="/digital-transformation" 
                activePageText="Formazione Sviluppo" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Partners:</span>
                        <a href="https://innovazionesviluppo.org/" target="_blank" rel="noreferrer">Innovazione per lo Sviluppo</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        Italia
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-time'></i>
                        </div>
                        <span>Duration:</span>
                        18 months 
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Status:</span>
                        On going
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={project} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Digital transformation</span>
                            <h3>The project</h3>
<p>
In Italy today there are about 250 organizations involved in international cooperation and nearly 400,000 third sector entities. Very few of these entities are equipped to collect, manage and use data in a systematic way, except perhaps the largest but mainly for fundraising purposes. While the outside world is asking entities to work with their data, very few are able to respond.

To respond to these needs, Soleterre and Gnucoop thought of providing their expertise by creating an enabling environment that facilitates the transition of third-sector and cooperative organizations to true data-organizations: "Get data!"

</p>
	    <p>
Further information at <a target="blank" href="https://academy.gnucoop.com/pages/innovazione-per-lo-sviluppo">https://academy.gnucoop.com</a>
</p>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}

const images = [
    (project1),
    (project2),
    (project3),
];

const Gallery = () => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);

    return (
        <section>
            <div className="gallery-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(false); 
                                        setPhotoIndex(0);}
                                    }
                                >
                                    <img src={project1} alt="event" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(true); 
                                        setPhotoIndex(1);}
                                    }
                                >
                                    <img src={project2} alt="event" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(true); 
                                        setPhotoIndex(2);}
                                    }
                                >
                                    <img src={project3} alt="event" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Lightbox */}
                {isOpenImage && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpenImage(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
            </section>
    );
}
